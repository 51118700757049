import { Injectable } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Store } from '@ngrx/store';
import { TranslatePipe } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';
import { FeatureType, StructureType } from '../enums';
import { Parts } from '../enums/parts.enum';
import { FeaturesCloneMoveDetailBuilder, LocalizableField, Structure } from '../models';
import { DataleanDataProviderService } from '../provider/datalean-data-provider.service';
import { ProductsActions } from '../store/app.actions';
import { BaseEditorService } from './base-editor.service';
import { ParseEntity } from '../helpers/utilities';
import { FeatureValue } from 'src/app/models/FeatureValue';
import { environment } from '../../environments/environment';

// not an injectable because it is not meant to be reused
@Injectable()
export class FeatureEditorService extends BaseEditorService {
  objectData: FeatureValue = new FeatureValue();
  constructor(protected dataleanDataService: DataleanDataProviderService, private translatePipe: TranslatePipe, private store: Store) {
    super(dataleanDataService);
  }

  init(communityUUID?: string) {
    this.objectData = {
      uuid: uuid(),
      name: this.translatePipe.transform('FEATURES.NEW_FEATURE'),
      onlyAdminEditable: false,
      featureValues: [],
      label: new LocalizableField(),
      parentUUID: null
    };

    if (communityUUID) {
      this.objectData.communityUUID = communityUUID;
    }
  }

  /**
   * inizializzi objectdata del servizio con una nuova categoria (con un uuid calcolato)
   * @param communityUUID id della comuniti alla quale è associata la categoria che stiamo inizializzando
   */
  initFeatureValue(communityUUID?: string) {
    this.objectData = {
      uuid: uuid(),
      name: this.translatePipe.transform('FEATURES.NEW_FEATUREVALUE'),
      editable: true,
      featureValues: [],
      gStorageEntityURL: new LocalizableField(),
      label: new LocalizableField(),
      iconURL: new LocalizableField(),
    };

    if (communityUUID) {
      this.objectData.communityUUID = communityUUID;
    }
  }

  getFeatures(communityUUID?: string, type?: FeatureType): Observable<{ result: FeatureValue[]; paginationInfo?: PageEvent }> {
    const params = {};
    if (communityUUID) {
      params['featureValueCommunityUUID'] = communityUUID;
    }
    if (type){
      params['type'] = type
    }
    return this.dataleanDataService
      .getDataForList<FeatureValue>(environment.featureValueUrl, params, [Parts.FEATURE_VALUES, Parts.FILTER_OPTIONS])
      .pipe(map((data) => ({ ...data, result: Array.isArray(data.result) ? data.result : Object.values(data.result) })));
  }

  create(): Observable<any> {
    const clear = this.clear();
    return this.dataleanDataService.createEntity(environment.featureValueUrl, clear, []);
  }

  createData(data: FeatureValue): Observable<any> {
    const clear = this.clear(data);
    return this.dataleanDataService.createEntity(environment.featureValueUrl, clear, [Parts.ALL]);
  }

  clone(objToSave: FeaturesCloneMoveDetailBuilder, communityUUID: string) {
    return this.dataleanDataService.clone(objToSave, communityUUID, [Parts.ALL], environment.featureValueUrl);
  }

  update(data: FeatureValue, communityUUID?: string, parts?: Parts[]): Observable<any> {
    if (!parts) {
      parts = [Parts.ALL];
    }
    const clear = this.clear(data);
    if (!communityUUID || communityUUID === 'all') {
      return this.dataleanDataService.updateEntity(environment.featureValueUrl, clear, parts);
    }
    return this.dataleanDataService.updateEntity(environment.featureValueUrl, clear, parts, undefined, { communityUUID });
  }

  delete(uuid: string): Observable<any> {
    return this.dataleanDataService.deleteEntity(environment.featureValueUrl, uuid);
  }

  deleteFeatureValue(parentUUID: string, nodeUUID: string) {
    return this.dataleanDataService.deleteEntity(environment.featureValueUrl + parentUUID + '/featureValue', nodeUUID);
  }

  getStructures() {
    this.dataleanDataService
      .getDataForList<Structure>(environment.structureUrl, { type: [StructureType.PRODUCT] }, [Parts.STRUCTURE_FIELDS])
      .subscribe({
        next: (structure) => {
          const structures = this.dataleanDataService.mapStructures(structure?.result);
          this.store.dispatch(
            ProductsActions.setProductStructures({ structures: structures.filter((s) => s.type === StructureType.PRODUCT) })
          );
        },
        error: (err) => console.error(err),
      });
  }

  private clear(data?: object): FeatureValue {
    let parsedEntity: FeatureValue;

    if (data) {
      parsedEntity = ParseEntity(new FeatureValue(), data);
    } else {
      parsedEntity = ParseEntity(new FeatureValue(), this.objectData);
    }

    parsedEntity.featureValues = parsedEntity.featureValues?.map((fv) => (!fv.codeName ? { ...fv, codeName: '' } : fv));

    return parsedEntity;
  }
}
