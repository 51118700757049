import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, retry, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth.service';
import { setAuthError } from '../pages/login/store/auth.actions';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add authorization header with jwt token if available
    if (!request.url.includes(environment.authenticationUrl)) {
      if (
        this.authService.idToken &&
        !request.url.includes('change-password') &&
        !request.url.includes('import') &&
        !request.url.includes('user-activate-via-code')
      ) {
        if (
          request.url.includes('export') ||
          (request.url.includes('media-library') && ['POST', 'PUT', 'PATCH'].includes(request.method))
        ) {
          request = request.clone({
            setHeaders: {
              Authorization: 'Bearer ' + this.authService.idToken,
            },
          });
        } else {
          request = request.clone({
            setHeaders: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + this.authService.idToken,
            },
          });
        }
      }
      if (request.url.includes('change-password') || request.url.includes('user-activate-via-code')) {
        request = request.clone({
          setHeaders: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        });
      }
      if (this.authService.idToken && request.url.includes('import')) {
        request = request.clone({
          setHeaders: {
            Accept: '*/*',
            Authorization: 'Bearer ' + this.authService.idToken,
          },
        });
      }
    }

    //per le post della media-library disabilito il service worker
    //ref. https://angular.io/guide/service-worker-devops#bypassing-the-service-worker
    //il service worker non fa funzionare gli eventi di upload
    //di conseguenza spacca tutti i loader progressivi della media-library
    if(request.url.includes('media-library') && request.method === 'POST'){
      request = request.clone({
        setHeaders: {
          'ngsw-bypass': 'true',
        },
      });
    }

    return next.handle(request).pipe(
      catchError((error) => {
        if ((error.status === 401) && !request.url.includes(environment.authenticationUrl)) {
          // this.authService.logout();
          return this.authService.tryToRefreshToken().pipe(
            switchMap(() => {
              return next.handle(
                request.clone({
                  setHeaders: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + this.authService.idToken,
                  },
                })
              );
            }),
            catchError((err) => {
              //auto logout if 401 response returned from api
              this.authService.logout();
              return throwError(() => error);
            })
          );
        }
        return throwError(() => error);
      }),
      retry({
        count: 2,
        resetOnSuccess: true,
        delay: (error, retryCount) => {
          retryCount++;
          if ((error.status === 429 || (error.status === 0 && navigator.onLine)) && retryCount < 2) {
            return of(500);
          }
          return throwError(() => error);
        },
      })
    );
  }
}
