import { SelectionModel } from '@angular/cdk/collections';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { GlobalPositionStrategy } from '@angular/cdk/overlay';
import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, EventEmitter, Injectable, Input, OnInit, Output } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { BehaviorSubject, Observable, of as observableOf, Subscription } from 'rxjs';
import { GridOption } from 'src/app/enums/grid-option.enum';
import { Permission } from 'src/app/enums/permission.enum';
import DeepCopy from 'src/app/helpers/deep_copy';
import { IsContainer } from 'src/app/helpers/structures';
import { Customization } from 'src/app/interfaces/customization';
import { CustomizationGroup } from 'src/app/interfaces/customization-group';
import { StructureField } from 'src/app/interfaces/structure-field';
import { Filter } from 'src/app/models/Filter';
import { ProductAnswer, ProductQuestion } from 'src/app/models/product-question';
import { ToLocalizedValuePipe } from '../../pipes/to-localized-value/to-localized-value';
import { UserPermissionService } from '../../services/user-permission.service';
import { ModalService } from '../modal/modal.service';
import { ViewPermissionComponent } from '../modal/view-permission/view-permission.component';
import { Structure } from '../../models/Structure';
import { FeatureValue } from 'src/app/models';

export enum NodeType {
  PARENT_TYPE = 'parent',
  CHILD_TYPE = 'children',
  CHILD_INCEST_TYPE = 'childrenIncest',
}

/**
 * File node data with nested structure.
 * Each node has a name, and a type or a list of children.
 */
export class Node {
  id: string;
  children: Node[];
  parentId: string;
  name: string;
  nodeType: NodeType;
  multipleSelection: any;
  selectable: boolean;
  editable: boolean;
  onlyAdminEditable: boolean;
  uuid: string;
  createChildren?: boolean;
}

/** Flat node with expandable and level information */
export class FlatNode {
  constructor(
    public expandable: boolean,
    public name: string,
    public level: number,
    public nodeType: NodeType,
    public id: string,
    public selectable: boolean,
    public editable: boolean,
    public parentId: string,
    public onlyAdminEditable: boolean,
    public uuid?: string,
    public createChildren?: boolean,
    public communityUUID?: string | null,
  ) {}
}

/**
 * File database, it can build a tree structured Json object from string.
 * Each node in Json object represents a file or a directory. For a file, it has name and type.
 * For a directory, it has name and children (a list of files or directories).
 * The input will be a json object string, and the output is a list of `Node` with nested
 * structure.
 */
@Injectable()
export class TreeDatabase {
  dataChange = new BehaviorSubject<Node[]>([]);
  type: string;

  get data(): Node[] {
    return this.dataChange.value;
  }

  constructor(private toLocalizedPipe: ToLocalizedValuePipe) {}

  initialize(treeData: any[], type: string) {
    // Build the tree nodes from Json object. The result is a list of `Node` with nested
    //     file node as children.
    let data: Node[];
    this.type = type;
    switch (type) {
      case 'features': {
        data = this.buildFeatureTree(treeData, 0);
        break;
      }
      case 'customizationGroup': {
        data = this.buildCustomizationTree(treeData, 0);
        break;
      }
      case 'questions': {
        treeData?.sort((a: ProductQuestion, b: ProductQuestion) => a.order - b.order);
        data = this.buildQuestions(treeData, 0);
        break;
      }
      case 'filters': {
        data = this.buildFilterTree(treeData, 0);
        break;
      }
      case 'structures': {
        treeData?.sort((a: Structure, b: Structure) => {
          if (a.order === b.order) {
            return a.name.localeCompare(b.name);
          }
          return a.order - b.order;
        });
        data = this.buildStructures(treeData, 0);
        break;
      }
    }

    // Notify the change.
    this.dataChange.next(data);
  }

  buildFilterTree(obj: Filter[], level: number, parentId?: string): Node[] {
    return obj?.map((filter) => {
      const node = new Node();
      node.name = this.toLocalizedPipe.transform(filter.displayName);
      node.id = filter.uuid;
      node.nodeType = NodeType.PARENT_TYPE;
      if (parentId) {
        node.parentId = parentId;
      }

      if (filter.children && filter.children.length > 0) {
        node.children = this.buildFilterTree(filter.children, level + 1, node.id);
      }
      if (level > 0) {
        node.nodeType = NodeType.CHILD_TYPE;
      }

      return node;
    });
  }

  /**
   * Build the file structure tree. The `value` is the Json object, or a sub-tree of a Json object.
   * The return value is the list of `Node`.
   */
  buildFeatureTree(obj: FeatureValue[], level: number, parentId?: string): Node[] {
    return obj?.map((feature) => {
      const node = new Node();
      node.name = this.toLocalizedPipe.transform(feature.name);
      node.id = feature.uuid;
      node.onlyAdminEditable = feature.onlyAdminEditable;
      node.nodeType = NodeType.PARENT_TYPE;
      if (parentId) {
        node.parentId = parentId;
      }

      if (feature.featureValues && feature.featureValues.length > 0) {
        node.children = this.buildFeatureTree(feature.featureValues, level + 1, node.id);
      }
      if (feature.parentUUID) {
        node.nodeType = NodeType.CHILD_TYPE;
        node.selectable = feature.clonable;
        node.editable = feature.editable;
      }

      return node;
    });
  }

  /**
   * Build the file customization tree. The `value` is the Json object, or a sub-tree of a Json object.
   * The return value is the list of `Node`.
   */
  buildCustomizationTree(obj: (CustomizationGroup | Customization)[], level: number): Node[] {
    return obj?.map((customizationGroup) => {
      const node = new Node();
      node.name = this.toLocalizedPipe.transform(customizationGroup.name);
      node.id = customizationGroup.uuid;
      node.nodeType = NodeType.CHILD_TYPE;

      if ('customizationBOList' in customizationGroup) {
        if (customizationGroup.customizationBOList && customizationGroup.customizationBOList.length > 0) {
          node.children = this.buildCustomizationTree(customizationGroup.customizationBOList, level + 1);
        }
        node.nodeType = NodeType.PARENT_TYPE;
      }

      return node;
    });
  }

  /**
   * Build the file structure tree. The `value` is the Json object, or a sub-tree of a Json object.
   * The return value is the list of `Node`.
   */
  buildStructures(obj: StructureField[], level: number, parentId?: string): Node[] {
    return obj?.map((structure) => {
      const node = new Node();
      node.name = this.toLocalizedPipe.transform(structure.name);
      node.id = structure.uuid;
      node.parentId = parentId;
      node.editable = !structure.onlyFE;
      if ('structureFields' in structure) {
        node.nodeType = NodeType.PARENT_TYPE;
        if (structure.structureFields && structure.structureFields.length > 0) {
          node.children = this.buildStructures(structure.structureFields, level + 1, node.id);
        }
      } else {
        node.nodeType = NodeType.CHILD_TYPE;
        // in this case structure is actually a structureField
        if (IsContainer(structure.type)) {
          node.nodeType = NodeType.CHILD_INCEST_TYPE;
          node.children = this.buildStructures(structure.fields, level + 1, node.id);
        }
      }
      return node;
    });
  }

  buildQuestions(obj: (ProductQuestion | ProductAnswer)[], level: number, parentId?: string): Node[] {
    return obj?.map((question) => {
      const node = new Node();
      node.name = this.toLocalizedPipe.transform(question.label);
      node.id = question.uuid;
      node.parentId = parentId;
      node.editable = true;
      node.createChildren = 'parentUUID' in question ? !question.isSectionController : true;
      node.nodeType = 'parentUUID' in question ? NodeType.CHILD_TYPE : NodeType.PARENT_TYPE;
      if (question.answers?.length > 0) {
        node.children = this.buildQuestions(question.answers, level + 1, node.id);
      }
      return node;
    });
  }

  updateSingleParent(parentData: StructureField | ProductQuestion | FeatureValue | CustomizationGroup | Customization | Filter) {
    let updatedNode: Node[];
    switch (this.type) {
      case 'features': {
        updatedNode = this.buildFeatureTree([parentData as FeatureValue], 0);
        break;
      }
      case 'customizationGroup': {
        updatedNode = this.buildCustomizationTree([parentData as CustomizationGroup], 0);
        break;
      }
      case 'structures': {
        updatedNode = this.buildStructures([parentData as StructureField], 0);
        break;
      }
      case 'questions': {
        updatedNode = this.buildQuestions([parentData as ProductQuestion], 0);
        break;
      }
      case 'filters': {
        updatedNode = this.buildFilterTree([parentData as Filter], 0);
        break;
      }
    }

    const newData = this.data.map((parentNode) => {
      if (parentNode.id === updatedNode[0]?.id) {
        return updatedNode[0];
      }
      return parentNode;
    });

    this.dataChange.next(newData);
  }
}

@Component({
  selector: 'app-draggable-tree',
  templateUrl: './draggable-tree.component.html',
  styleUrls: ['./draggable-tree.component.scss'],
  providers: [TreeDatabase, ToLocalizedValuePipe],
})
export class DraggableTreeComponent implements OnInit {
  readonly NODE_TYPE = NodeType;
  treeControl: FlatTreeControl<FlatNode>;
  treeFlattener: MatTreeFlattener<Node, FlatNode>;
  dataSource: MatTreeFlatDataSource<Node, FlatNode>;
  dataSourceBackup: Node[] = undefined;
  // expansion model tracks expansion state
  expansionModel = new SelectionModel<string>(true);
  dragging = false;
  expandTimeout: any;
  expandDelay = 1000;
  validateDrop = false;
  updatedElements: Array<string> = [];
  addedElements: Array<string> = [];
  selectedElements = {};
  dataChangeSubscription: Subscription;
  activeNode: Node;

  @Input() createOptions: any = undefined;
  @Input() hasSearch = true;
  @Input() canDrag = true;
  @Input() onlyFatherSelection = false;
  @Input() canEdit = true;
  @Input() canCreate = true;
  @Input() canDragRoot = false;
  @Input() canCreateChild = true;
  @Input() treeData: [string, any];
  @Input() manageBulk = false;
  @Input() options: Array<any>;
  @Input() permissionUrl: string;
  @Input() childrenUrl: string;
  @Input() errorNodes: Set<string> = new Set();
  @Input() warnNodes: Set<string> = new Set();

  @Output() onAddChildren = new EventEmitter<Node>();
  @Output() onDelete = new EventEmitter<any>();
  @Output() onAdd = new EventEmitter<any>();
  @Output() onSelect = new EventEmitter<any>();
  @Output() multiSelect = new EventEmitter<any>();
  @Output() onDrop = new EventEmitter<any>();
  @Output() onClickNode = new EventEmitter<any>();
  @Output() onClone = new EventEmitter<FlatNode>();

  constructor(private database: TreeDatabase, private userPermissionService: UserPermissionService, private modalService: ModalService) {}

  private _getLevel = (node: FlatNode) => node.level;
  private _isExpandable = (node: FlatNode) => node.expandable;
  private _getChildren = (node: Node): Observable<Node[]> => observableOf(node.children);
  hasChild = (_: number, _nodeData: FlatNode) => _nodeData.expandable;

  ngOnInit(): void {
    if (!this.canEdit) {
      this.canDrag = false;
    }
    if (!this.manageBulk) {
      this.manageBulk = this.canCreate;
    }
    this.init();
  }

  /**
   * Not used but you might need this to programmatically expand nodes
   * to reveal a particular node
   */
  private expandNodesById(flatNodes: Array<FlatNode | Node>, ids: string[]) {
    if (!flatNodes?.length) {
      return;
    }
    this.treeControl.collapseAll();

    while (ids.length > 0) {
      const currentId = ids.pop();
      const node = this.treeControl.dataNodes.find((controlNode) => controlNode.id === currentId);
      this.treeControl.expand(node);
    }
  }

  private doGetParentNode(parent: Node, node: Node): Node {
    if (parent) {
      let result = parent.children?.find((child: Node) => child.id === node.id);
      if (!result) {
        result = this.doGetParentNode(
          parent.children?.find((child: Node) => child.id !== node.id && child.children),
          node
        );
      }
      return result ? parent : undefined;
    }
    return undefined;
  }

  init() {
    this.selectedElements = {};
    this.treeFlattener = new MatTreeFlattener(this.transformer, this._getLevel, this._isExpandable, this._getChildren);
    this.treeControl = new FlatTreeControl<FlatNode>(this._getLevel, this._isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    if (!this.dataChangeSubscription) {
      this.dataChangeSubscription = this.database.dataChange.subscribe((data) => {
        if (data) {
          this.rebuildTreeForData(data);
          if (!this.dataSourceBackup || this.dataSourceBackup?.length === 0) {
            this.dataSourceBackup = data;
          }
        }
      });
    }
    this.database.initialize(this.treeData[1], this.treeData[0]);
  }

  refreshDatabase(parentNode?: any, data: [string, any[]] = this.treeData) {
    if (!parentNode) {
      this.database.initialize(data[1], data[0]);
    } else {
      this.database.updateSingleParent(parentNode);
    }
  }

  transformer = (node: Node, level: number) => {
    return new FlatNode(
      !!node.children,
      node.name,
      level,
      node.nodeType,
      node.id,
      node.selectable,
      node.editable,
      node.parentId,
      node.onlyAdminEditable,
      node.id,
      node.createChildren
    );
  };

  // DRAG AND DROP METHODS

  shouldValidate(event: MatCheckboxChange): void {
    this.validateDrop = event.checked;
  }

  onSearch(key: string) {
    this.expansionModel.clear();
    if (key.trim().length > 0) {
      let data = structuredClone(this.dataSourceBackup);
      data = this.deepFilterByName(data, key);
      this.rebuildTreeForData(data);
    } else this.rebuildTreeForData(this.dataSourceBackup);
  }

  deepFilterByName(featureValues: Node[], key: string): Node[] {
    return featureValues.filter((featureValue) => {
      if (featureValue.name.toLowerCase().indexOf(key.toLowerCase()) > -1) {
        this.expansionModel.toggle(featureValue.id);
        return true;
      }
      if (featureValue.children?.length) {
        featureValue.children = this.deepFilterByName(featureValue.children, key);
        return featureValue.children?.length > 0;
      }
      return false;
    });
  }

  /**
   * This constructs an array of nodes that matches the DOM
   */
  visibleNodes(): Node[] {
    return structuredClone(this.dataSource.data);
  }

  findParentNode(node: Node | FlatNode) {
    const visibleNodes = this.visibleNodes();
    let result = visibleNodes.find((parent) => parent.id === node.parentId);
    if (!result) {
      result = visibleNodes.map((child) => this.doFindParentNode(child.children ?? [], node)).filter((n) => !!n)[0];
    }

    return result;
  }

  doFindParentNode(fields: Node[], node: Node | FlatNode): Node {
    let result = fields.find((parent) => parent.id === node.parentId);
    if (!result) {
      result = fields.map((child) => this.doFindParentNode(child.children ?? [], node)).filter((n) => !!n)[0];
    }
    return result;
  }

  // recursive find function to find siblings of node
  findNodeSiblings(arr: Array<Node>, id: string): Array<Node> | null {
    const found = arr.find((node) => node.id === id);
    if (found) {
      return arr;
    }

    for (let i = 0; i < arr.length; i++) {
      if (arr[i].children) {
        const result = this.findNodeSiblings(arr[i].children, id);
        if (result?.length) {
          return result;
        }
      }
    }
    return null;
  }

  /**
   * Handle the drop - here we rearrange the data based on the drop event,
   * then rebuild the tree.
   */
  drop(event: CdkDragDrop<string[]>) {
    if (event.currentIndex !== event.previousIndex) {
      this.activeNode = event.item.data;
      // ignore drops outside of the tree
      if (!event.isPointerOverContainer) return;

      let nodeAtDest: Node;
      // determine where to insert the node
      if (event.item.data.nodeType === NodeType.CHILD_TYPE || event.item.data.nodeType === NodeType.CHILD_INCEST_TYPE) {
        const parent = this.findParentNode(event.item.data);
        if (parent) {
          const currIndex = parent.children.findIndex((elem) => elem.id === event.item.data.id);
          const calcIndex = event.currentIndex - event.previousIndex;
          nodeAtDest = parent.children[currIndex + calcIndex];
        }
      } else {
        const visibleNodes = this.visibleNodes();
        nodeAtDest =
          visibleNodes[visibleNodes.findIndex((elem) => elem.id === event.item.data.id) + (event.currentIndex - event.previousIndex)];
      }

      if (!nodeAtDest) {
        return;
      }
      const newSiblings = this.findNodeSiblings(this.dataSource.data, nodeAtDest.id);
      if (!newSiblings) return;
      const insertIndex = newSiblings.findIndex((s) => s.id === nodeAtDest.id);

      this.onDrop.emit({ node: this.activeNode, newIndex: insertIndex });
    }
  }

  /**
   * Experimental - opening tree nodes as you drag over them
   */
  dragStart() {
    this.dragging = true;
  }

  dragEnd() {
    this.dragging = false;
  }

  dragHover(node: FlatNode) {
    if (this.dragging) {
      clearTimeout(this.expandTimeout);
      this.expandTimeout = setTimeout(() => {
        this.treeControl.expand(node);
      }, this.expandDelay);
    }
  }

  dragHoverEnd() {
    if (this.dragging) {
      clearTimeout(this.expandTimeout);
    }
  }

  /**
   * The following methods are for persisting the tree expand state
   * after being rebuilt
   */

  rebuildTreeForData(data: Node[]) {
    this.dataSource.data = DeepCopy(data) as Node[];
    this.expandNodesById(
      data,
      this.treeControl.expansionModel.selected.map((node) => node?.id).filter((n) => !!n)
    );
  }

  addChildren(node: Node) {
    this.expansionModel.select(node.id);
    this.onAddChildren.emit(node);
  }

  deleteNode(node: Node) {
    this.onDelete.emit(node);
  }

  featureAdd() {
    this.onAdd.emit(true);
  }

  needSave(node: Node) {
    // node is an updated element or an added element
    return this.updatedElements.includes(node.id) || this.addedElements.includes(node.id);
  }

  toggleExpansion(node: FlatNode, activate: any) {
    if (this.treeControl.isExpanded(node)) {
      this.expansionModel.select(node.id);
    } else {
      this.expansionModel.deselect(node.id);
    }

    if (activate) {
      this.activeNode = { ...node, children: undefined, multipleSelection: undefined, uuid: undefined } as Node;
      this.onClickNode.emit(node);
    }
  }

  unselect(node: Node) {
    this.dataSource.data.forEach((file) => {
      const parent = this.doGetParentNode(file, node);
      if (parent) {
        if (this.selectedElements[parent.id]) {
          this.selectedElements[parent.id] = this.selectedElements[parent.id].filter((nodes: any) => nodes !== node.id);
          if (this.selectedElements[parent.id].length === 0) delete this.selectedElements[parent.id];
        }
      }
    });
  }

  select(node: Node) {
    this.dataSource.data.forEach((file) => {
      const parent = this.doGetParentNode(file, node);
      if (parent) {
        this.selectedElements[parent.id] ? this.selectedElements[parent.id].push(node.id) : (this.selectedElements[parent.id] = [node.id]);
      }
    });
    this.onSelect.emit(node);
  }

  canSelectMultiple(node: Node) {
    let result = true;
    this.dataSource.data.forEach((file) => {
      const parent = this.doGetParentNode(file, node);
      if (parent) {
        if (parent.multipleSelection !== undefined && !parent.multipleSelection) {
          result = (this.selectedElements[parent.id] && this.selectedElements[parent.id].length === 0) || !this.selectedElements[parent.id];
        }
      }
    });
    return result;
  }

  selectElement($event: { checked: any }, node: Node) {
    if ($event.checked) {
      this.select(node);
    } else {
      this.unselect(node);
    }
    this.multiSelect.emit(this.selectedElements);
  }

  selectAllElement($event: { checked: any }, node: Node) {
    this.dataSource.data.forEach((file) => {
      if (file.id === node.id) {
        if ($event.checked) {
          if (!this.onlyFatherSelection)
            file.children?.forEach((child) => {
              if (child.selectable) this.select(child);
            });
          else this.selectedElements[node.id] = [node.id];
        } else {
          if (!this.onlyFatherSelection)
            file.children?.forEach((child) => {
              if (child.selectable) this.unselect(child);
            });
          else delete this.selectedElements[node.id];
        }
      }
    });
    this.multiSelect.emit(this.selectedElements);
  }

  isSelected(node: Node) {
    let result = false;
    this.dataSource.data.forEach((file) => {
      const parent = this.doGetParentNode(file, node);
      if (parent && this.selectedElements[parent.id]) {
        result = this.selectedElements[parent.id].indexOf(node.id) !== -1;
      }
    });
    return result;
  }

  hasSelectableElements(node: Node) {
    let result = false;
    this.dataSource.data.forEach((file) => {
      if (file.id === node.id) {
        file.children?.forEach((child) => {
          if (child.selectable) result = true;
        });
      } else {
        node.children?.forEach((child: Node) => {
          if (child.selectable) result = true;
        });
      }
    });
    return result;
  }

  isAllSelected(node: Node) {
    const selectableElements = [];
    const selectedElements = [];

    this.dataSource.data.forEach((file) => {
      if (file.id === node.id) {
        file.children?.forEach((child) => {
          if (child.selectable) {
            selectableElements.push(child.id);
            if (this.isSelected(child)) selectedElements.push(child.id);
          }
        });
      } else {
        node.children?.forEach((child: Node) => {
          if (child.selectable) {
            selectableElements.push(child.id);
            if (this.isSelected(child)) selectedElements.push(child.id);
          }
        });
      }
    });

    return selectableElements.length > 0 && selectableElements.length === selectedElements.length;
  }

  checkIfCanManage(node: Node) {
    if (node.nodeType !== 'parent') return this.manageBulk || node.editable;
    else return this.canCreate;
  }

  checkIfCanEditNode(node: FlatNode) {
    if (node.onlyAdminEditable) {
      return this.canEdit && this.userPermissionService.getPermissionByName(Permission.REMOVE_ADMIN_FEATURES.name);
    } else {
      return this.canEdit;
    }
  }

  onAddEntityClick(option: any) {
    const type = option
      .toString()
      .toLowerCase()
      .substr(option.toString().toLowerCase().lastIndexOf('.') + 1, option.toString().toLowerCase().length);
    this.onAdd.emit(type);
  }

  manageOptionEvent(event, node: FlatNode) {
    const op = event.event.operation;
    if (op === GridOption.PERMISSION) {
      const rootIds = [...this.dataSource.data].map((v) => v.id);
      const isChildren = !rootIds.includes(node.id);
      const entityUrl = isChildren ? this.childrenUrl : this.permissionUrl;

      this.modalService.open({
        component: ViewPermissionComponent,
        class: 'product-view-permission-modal',
        canExitThroughBackdropClick: true,
        position: new GlobalPositionStrategy().centerHorizontally().centerVertically(),
        data: {
          entityUrl,
          entityUUID: node.id,
          childUrl: this.childrenUrl,
          childUUID: node.id,
          children: isChildren,
        },
      });
    } else if (op === GridOption.CLONE) {
      this.onClone.emit(node);
    }
  }

  isInError(node: Node) {
    return this.errorNodes ? !![...this.errorNodes].find((v) => v === node.uuid)?.length : false;
  }

  containsError(node: Node) {
    return this.warnNodes ? !![...this.warnNodes].find((v) => v === node.uuid)?.length : false;
  }
}
